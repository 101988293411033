<template>
  <div class="container-view">
    <ComponentSideBar v-if="storeAuth.isAuthenticated"></ComponentSideBar>
    <div class="container-content-router-view">

      <ComponentHeaderBar v-if="storeAuth.isAuthenticated"></ComponentHeaderBar>
      <router-view></router-view>

    </div>
  </div>
  <footer v-if="storeAuth.isAuthenticated">

    <div class="row">
      <div class="footer-container">
        <img height="50" src="../src/assets/shared/logo_ras_bandiera.svg" alt="">
        <img height="50" src="../src/assets/shared/federconsumatori-sardegna.png" alt="Image 1">
        <img height="50" src="../src/assets/shared/logo_udicon_REGIONALE_SARDEGNA.png" alt="Image 2">
        <img height="50" src="../src/assets/shared/logo-adoc-2020.png" alt="Image 3">
        <img height="50" src="../src/assets/shared/logo-casa-del-consumatore-2022.svg" alt="Image 4">
      </div>
    </div>
    <div class="row">
    <span class="footer-message">
      RETE DEGLI SPORTELLI REGIONALI D.D. 04/08/2023
PROGRAMMA GENERALE DELLA REGIONE SARDEGNA FINANZIATO CON I FONDI DEL MINISTERO DELLE IMPRESE E DEL MADE IN ITALY AI SENSI DEL D.M. 06/05/2022
    </span>
    </div>
  </footer>
</template>

<script>

import ComponentSideBar from "@/components/sidebar/ComponentSideBar";
import {useAuthStore} from "@/stores/auth";
import ComponentHeaderBar from "@/components/header/ComponentHeaderBar";
import {useUserStore} from "@/stores/users";
import {calculateMargin} from "@/main";
import axios from "axios";

export default {
  name: 'App',
  components: {ComponentHeaderBar, ComponentSideBar},
  setup() {
    const storeAuth = useAuthStore()
    const storeUser = useUserStore()
    return {
      storeAuth,
      storeUser
    }
  },
  data() {
    return {
      dropdownState: false,
      showModal: false
    }
  },
  created() {
    this.resetStateStoreModal()
    this.emitter.on('checkStateDropdownMenu', (bool) => {
      this.dropdownState = bool
    })
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    if (useAuthStore().bearerToken) {
      axios.defaults.headers['Authorization'] = 'Bearer ' + useAuthStore().bearerToken
    }
    // this.download()

    calculateMargin()
    if (window.innerWidth < window.innerHeight) {
      this.changeVariables()
    } else {
      this.resetVariables()
    }
    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth < e.target.innerHeight) {
        this.changeVariables()
      } else {
        this.resetVariables()
      }
      calculateMargin()
    })
    window.addEventListener('click', (e) => {
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      let container_name = document.getElementsByClassName('container-name')[0]
      if (container_dropdown_menu_header_bar && container_name) {
        if (!container_dropdown_menu_header_bar.contains(e.target) && !container_name.contains(e.target)) {
          this.dropdownState = false
          if (!this.dropdownState) {
            this.emitter.emit('closeDropdownMenu')
          }
        }
      }
    })
  },
  methods: {
    download() {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + useAuthStore().userGetters.token
        }
      }

      axios.get('api/reception-cards/10/template/', config)
          .then(res => {
            console.log(res)
            const url = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'static but for now.pdf'; // Replace with your desired file name
            link.click();
            console.log('SEI BELLO')
          })
          .catch(() => {
            console.log('FAI SCHIFO')
          })
    },
    resetStateStoreModal() {
      this.showModal = false
    },
    changeVariables() {
      document.documentElement.style.setProperty('--x-small', '1.5vmax');
      document.documentElement.style.setProperty('--small', '1.7vmax');
      document.documentElement.style.setProperty('--medium', '2.5vmax');
      document.documentElement.style.setProperty('--xx-large', '7vmax');
    },
    resetVariables() {

      document.documentElement.style.setProperty('--x-small', '1.5vmin');
      document.documentElement.style.setProperty('--small', '1.7vmin');
      document.documentElement.style.setProperty('--medium', '2.5vmin');
      document.documentElement.style.setProperty('--xx-large', '7vmin');
    }
  },
  updated() {
    calculateMargin()
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
    })
    window.removeEventListener('click', calculateMargin)
    this.emitter.off('checkStateDropdownMenu', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    })
    window.removeEventListener('click', calculateMargin)
  }
}
</script>

<style>
.container-buttons {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.btn-danger-custom {
  margin-left: 10px;
}

.container-view {
  display: flex;
  background: #F3F3F3;
  min-height: 100vh;
}

.container-content-router-view {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color-f4f4f4);
  width: 100%;
}

footer {
  background: var(--color-f4f4f4);
  width: 84vw;
  margin: 10px 0px 0px 15vw;
  padding: 10px 0px 10px 0px;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  padding: 10px 0px 10px 0px;
}

.footer-message {
  margin: 10px 0px 10px 0px !important;
  display: flex;
  text-align: center;
}


.container-bottom {
  padding: 25px;
  background: var(--color-f4f4f4);
  display: flex;
  flex-direction: column;
  z-index: 30;
  align-items: center;
  top: 0;
}

#app {
  min-height: 100vh;
}
</style>
