import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";

const routes = [
    // {
    //     path: '/register',
    //     name: 'register',
    //     meta: {
    //         hideForAuth: true
    //     },
    //     // component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterView')
    // },
    {
        path: '/login',
        name: 'login',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword')
    },
    {
        path: '/',
        name: 'home',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ProfileView" */ '../views/ProfileView')
    },
    {
        path: '/pratiche',
        name: 'pratiche',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "PraticheView" */ '../views/PraticheView')
    },
    {
        path: '/contatti',
        name: 'contatti',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ContattiView" */ '../views/ContattiView')
    },
    {
        path: '/conciliazioni',
        name: 'conciliazioni',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ConciliazioniView" */ '../views/ConciliazioniView')
    },
    {
        path: '/reports',
        name: 'reports',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ReportView" */ '../views/ReportView')
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ReportView" */ '../views/PrivacyView')
    },
    {
        path: '/check-request-reset-password/:uidb64/:token/',
        name: 'check-request-reset-password',
        meta: {
            hideForAuth: true,
            stepData: 3
        },
        component: () => import(/* webpackChunkName: "DetailReceptionCardView" */ '../views/ResetPassword')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (useAuthStore().isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});


router.beforeEach((to, from, next) => {
    // l'utente operatore viene riportato alla home se prova ad accedere ai seguenti path
    if (useAuthStore().userGetters && useAuthStore().userGetters.user_type === 'o' &&
        ((to.name === 'services') || (to.name === 'structures') || (to.name === 'operators') || (to.name === 'admins') ||
            (to.name === 'chronology') || (to.name === 'questions'))) {
        next("/");
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (useAuthStore().isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});


export default router
