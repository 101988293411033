import {defineStore} from 'pinia'
import axios from "axios";

export const useContattiStore = defineStore({
    id: 'contatto',


    state: () => ({
        contatti: [],
        totContatti: 0,
        contatto: null,
        lastResponse: null,
        spinnerListContatti: false,
        optionsContatti: null
        //page: 1,

    }),
    actions: {
        resetStatesForListContatti() {
            this.contatti = []
            this.lastResponse = null
            this.page = 1
            this.totContatti = 0
        },

        getOptionsContatti() {
          return new Promise((resolve, reject) => {
                axios.options("api/contatti/")
                    .then(res => {
                        this.optionsContatti = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },

        getContatti(params) {
             return new Promise((resolve, reject) => {
                this.spinnerListContatti = true;

                const getData = async () => {
                  try {
                    this.contatti = [];
                    //console.log('nello store: ',params)
                    const response = await axios.get("api/contatti/",
                        {params: params
                            });
                    await response;
                    for (let i = 0; i < response.data.results.length; i++) {
                        this.contatti.push(response.data.results[i]);
                    }
                    //console.log('response:', response.data)
                    this.totContatti = response.data.count;
                    this.lastResponse = response.data;
                    //this.page++;
                    resolve(response);
                  } catch (error) {
                    console.error('Error fetching data:', error);
                    reject(error);
                  }
                };
                getData();
                resolve();
              })
                .catch(() => {

                })
                .finally(() => {
                  this.spinnerListContatti = false;
                });
        },

        newContatto(payload) {
            return new Promise((resolve, reject) => {
                axios.post("api/contatti/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })

        },

        patchContatto(id, payload) {
            return new Promise ((resolve, reject) => {
                axios.patch("api/contatti/" + id +"/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

    },

    getters: {
        contattiGetters: state => {
            return state.contatti
        },
        contattoGetters: state => {
            return state.contatto
        },
        lastResponseGetters: state => {
            return state.lastResponse
        },
        pageGetters: state => {
            return state.page
        },
        spinnerListContattiGetters: state => {
            return state.spinnerListContatti
        },
        totContattiGetters: state =>{
            return state.totContatti
        },
        optionsContattiGetters: state =>{
            return state.optionsContatti
        },
    }
})


