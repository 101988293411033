import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import {createPinia} from "pinia";
import mitt from "mitt";
import axios from "axios";
import BootstrapVueNext from "bootstrap-vue-next";
import {vBTooltip} from "bootstrap-vue-next";
import {createPersistedStatePlugin} from "pinia-plugin-persistedstate-2";
import './assets/css/main.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.css'
import {useAuthStore} from "@/stores/auth";

const pinia = createPinia()
const emitter = mitt();

// create an .env.local file to store the local backend address. This will prevent the setting from being tracked on git
// for more environment variables, if you are using Vue cli 3, only variables that start with VUE_APP_ will be loaded.
// An env file for a specific mode (e.g. .env.production) will take higher priority than a generic one (e.g. .env).
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST


axios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
})

export function calculateMargin() {
    // prendo il container che ha all'interno il contenuto centale
    let container_content_router_view = document.getElementsByClassName('container-content-router-view')[0]

    if (useAuthStore().isAuthenticated) {
         // prendo l'elemento sidebar
        let container_sidebar = document.getElementsByClassName('container-sidebar')[0]
        // setto il margine sinistro al container di destra rispetto al sidebar menu
        if (container_sidebar && container_content_router_view) {
            container_content_router_view.style.marginLeft = container_sidebar.getBoundingClientRect().width + 'px'
            container_content_router_view.style.minWidth = 'calc(100% - ' + container_content_router_view.style.marginLeft + ')'
        } else {
            container_content_router_view.style.marginLeft = 0 + 'px'
        }
    } else {
        container_content_router_view.style.marginLeft = 0 + 'px'
    }
}

//export function tokenUpdate(err,store,state) {
//    delete axios.defaults.headers['Authorization']
//    useAuthStore().getRefreshToken({
//        refresh: useAuthStore().refreshTokenGetters
//    })
//        .then(res => {
//            useAuthStore().bearerToken = res.data.access
//            axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.access
//            err.config.headers['Authorization'] = 'Bearer ' + res.data.access
//
//            axios.request(err.config)
//                .then(resp => {
//                    if (state)
//                        store[state] = resp.data
//                })
//                .catch(() => {})
//
//        })
//        .catch(() => {
//            useAuthStore().$reset()
//            router.push({name: 'login'}).catch(() => {})
//        })
//}

export const name_project = 'Pratiche'

const app = createApp(App)
app.directive('b-tooltip',vBTooltip)
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(BootstrapVueNext)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')