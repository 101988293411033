import {defineStore} from 'pinia'
import axios from "axios";

export const usePraticheStore = defineStore({
    id: 'pratica',


    state: () => ({
        pratiche: [],
        totPratiche: 0,
        pratica: null,
        lastResponse: null,
        spinnerListPratiche: false,
        optionsPratiche: null
        //page: 1,

    }),
    actions: {
        resetStatesForListPratiche() {
            this.pratiche = []
            this.lastResponse = null
            this.page = 1
            this.totPratiche = 0
        },

        getOptionsPratiche() {
          return new Promise((resolve, reject) => {
                axios.options("api/pratiche/")
                    .then(res => {
                        this.optionsPratiche = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },

        getPratiche(params) {
             return new Promise((resolve, reject) => {
                this.spinnerListPratiche = true;

                const getData = async () => {
                  try {
                    this.pratiche = [];
                    //console.log('nello store: ',params)
                    const response = await axios.get("api/pratiche/",
                        {params: params
                            });
                    await response;
                    for (let i = 0; i < response.data.results.length; i++) {
                        this.pratiche.push(response.data.results[i]);
                    }
                    //console.log('response:', response.data)
                    this.totPratiche = response.data.count;
                    this.lastResponse = response.data;
                    //this.page++;
                    resolve(response);
                  } catch (error) {
                    console.error('Error fetching data:', error);
                    reject(error);
                  }
                };
                getData();
              })
                .catch(() => {

                })
                .finally(() => {
                  this.spinnerListPratiche = false;
                });
        },

        newPratica(payload) {
            return new Promise((resolve, reject) => {
                axios.post("api/pratiche/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })

        },

        patchPratica(id, payload) {
            return new Promise ((resolve, reject) => {
                axios.patch("api/pratiche/" + id +"/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

    },

    getters: {
        praticheGetters: state => {
            return state.pratiche
        },
        praticaGetters: state => {
            return state.pratica
        },
        lastResponseGetters: state => {
            return state.lastResponse
        },
        pageGetters: state => {
            return state.page
        },
        spinnerListPraticheGetters: state => {
            return state.spinnerListPratiche
        },
        totPraticheGetters: state =>{
            return state.totPratiche
        },
        optionsPraticheGetters: state =>{
            return state.optionsPratiche
        },
    }
})


