import {defineStore} from 'pinia'
import axios from "axios";

export const useConciliazioniStore = defineStore({
    id: 'conciliazione',


    state: () => ({
        conciliazioni: [],
        totConciliazioni: 0,
        conciliazione: null,
        lastResponse: null,
        spinnerListConciliazioni: false,
        optionsConciliazioni: null
        //page: 1,

    }),
    actions: {
        resetStatesForListConciliazioni() {
            this.conciliazioni = []
            this.lastResponse = null
            this.page = 1
            this.totConciliazioni = 0
        },

        getOptionsConciliazioni() {
          return new Promise((resolve, reject) => {
                axios.options("api/conciliazioni/")
                    .then(res => {
                        this.optionsConciliazioni = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },

        getConciliazioni(params) {
             return new Promise((resolve, reject) => {
                this.spinnerListConciliazioni = true;

                const getData = async () => {
                  try {
                    this.conciliazioni = [];
                    //console.log('nello store: ',params)
                    const response = await axios.get("api/conciliazioni/",
                        {params: params
                            });
                    await response;
                    for (let i = 0; i < response.data.results.length; i++) {
                        this.conciliazioni.push(response.data.results[i]);
                    }
                    //console.log('response:', response.data)
                    this.totConciliazioni = response.data.count;
                    this.lastResponse = response.data;
                    //this.page++;
                    resolve(response);
                  } catch (error) {
                    console.error('Error fetching data:', error);
                    reject(error);
                  }
                };
                getData();
                resolve();
              })
                .catch(() => {

                })
                .finally(() => {
                  this.spinnerListConciliazioni = false;
                });
        },

        newConciliazione(payload) {
            return new Promise((resolve, reject) => {
                axios.post("api/conciliazioni/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })

            })

        },

        patchConciliazione(id, payload) {
            return new Promise ((resolve, reject) => {
                axios.patch("api/conciliazioni/" + id +"/", payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

    },

    getters: {
        conciliazioniGetters: state => {
            return state.conciliazioni
        },
        conciliazioneGetters: state => {
            return state.conciliazione
        },
        lastResponseGetters: state => {
            return state.lastResponse
        },
        pageGetters: state => {
            return state.page
        },
        spinnerListConciliazioniGetters: state => {
            return state.spinnerListConciliazioni
        },
        totConciliazioniGetters: state =>{
            return state.totConciliazioni
        },
        optionsConciliazioniGetters: state =>{
            return state.optionsConciliazioni
        },
    }
})


