<template>
  <div class="container-sidebar">
    <!--    <div class="container-logo">-->
    <!--      <img class="img-logo" width="300" height="150" src="../../assets/sidebar/Logo_istituzionale_Regione_Sardegna_(2021).svg" alt="">-->
    <!--    </div>-->
    <div class="container-links">
      <div :class="{'container_single_link': $route.path === '/'}" @click="goToPath('home')" v-if="$route.path === '/'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/home_active.svg" alt="Home">
        <span v-if="!switchText" class="text-link text-link-active">Home</span>
        <span v-else class="text-link text-link-active">Home</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/'}" @click="goToPath('home')" v-else
           class="container-single-link">
        <img src="../../assets/sidebar/home.svg" alt="Home">
        <span v-if="!switchText" class="text-link text-link">Home</span>
        <span v-else class="text-link text-link">Home</span>
      </div>

      <div :class="{'container_single_link': $route.path.substring(0,14) === '/pratiche'}" @click="goToPath('pratiche')"
           v-if="$route.path.substring(0,14) === '/pratiche'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/file-text_active.svg" alt="Pratiche">
        <span v-if="!switchText" class="text-link text-link-active">Pratiche</span>
        <span v-else class="text-link text-link-active">Pratiche</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/pratiche'}" @click="goToPath('pratiche')"
           v-else
           class="container-single-link">
        <img src="../../assets/sidebar/file-text.svg" alt="Pratiche">
        <span v-if="!switchText" class="text-link text-link">Pratiche</span>
        <span v-else class="text-link text-link">Pratiche</span>
      </div>

      <div :class="{'container_single_link': $route.path.substring(0,14) === '/contatti'}" @click="goToPath('contatti')"
           v-if="$route.path.substring(0,14) === '/contatti'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/file-text_active.svg" alt="Contatti">
        <span v-if="!switchText" class="text-link text-link-active">Contatti</span>
        <span v-else class="text-link text-link-active">Contatti</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/contatti'}" @click="goToPath('contatti')"
           v-else
           class="container-single-link">
        <img src="../../assets/sidebar/file-text.svg" alt="Contatti">
        <span v-if="!switchText" class="text-link text-link">Contatti</span>
        <span v-else class="text-link text-link">Contatti</span>
      </div>

      <div :class="{'container_single_link': $route.path.substring(0,14) === '/conciliazioni'}"
           @click="goToPath('conciliazioni')"
           v-if="$route.path.substring(0,14) === '/conciliazioni'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/file-text_active.svg" alt="Conciliazioni">
        <span v-if="!switchText" class="text-link text-link-active">Conciliazioni</span>
        <span v-else class="text-link text-link-active">Conciliazioni</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/conciliazioni'}" @click="goToPath('conciliazioni')"
           v-else
           class="container-single-link">
        <img src="../../assets/sidebar/file-text.svg" alt="Conciliazioni">
        <span v-if="!switchText" class="text-link text-link">Conciliazioni</span>
        <span v-else class="text-link text-link">Conciliazioni</span>
      </div>

      <div :class="{'container_single_link': $route.path.substring(0,8) === '/reports'}" @click="goToPath('reports')"
           v-if="$route.path.substring(0,8) === '/reports'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/clipboard_active.svg" alt="Report">
        <span v-if="!switchText" class="text-link text-link-active">Report</span>
        <span v-else class="text-link text-link-active">Report</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/reports'}" @click="goToPath('reports')" v-else
           class="container-single-link">
        <img src="../../assets/sidebar/clipboard.svg" alt="Report">
        <span v-if="!switchText" class="text-link text-link">Report</span>
        <span v-else class="text-link text-link">Report</span>
      </div>

<!--      <div :class="{'container_single_link': $route.path.substring(0,8) === '/privacy'}" @click="goToPath('privacy')"-->
<!--           v-if="$route.path.substring(0,8) === '/privacy'"-->
<!--           class="container-single-link background_active"-->
<!--           style="position: absolute; bottom: 0">-->
<!--        <img src="../../assets/sidebar/clipboard_active.svg" alt="Privacy">-->
<!--        <span v-if="!switchText" class="text-link text-link-active">Informativa sulla privacy</span>-->
<!--        <span v-else class="text-link text-link-active">Informativa sulla privacy</span>-->
<!--      </div>-->
<!--      <div :class="{'container_single_link': $route.path === '/privacy'}" @click="goToPath('privacy')" v-else-->
<!--           class="container-single-link"-->
<!--           style="position: absolute; bottom: 0">-->
<!--        <img src="../../assets/sidebar/clipboard.svg" alt="Privacy">-->
<!--        <span v-if="!switchText" class="text-link text-link">Informativa sulla privacy</span>-->
<!--        <span v-else class="text-link text-link">Informativa sulla privacy</span>-->
<!--      </div>-->

      <!--DA QUI IN POI-->
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentSideBar",
  setup() {
    const storeAuth = useAuthStore()
    return {
      storeAuth
    }
  },
  data() {
    return {
      switchChecked: null,
      switchText: false
    }
  },
  created() {
  },
  mounted() {
    if (window.innerWidth < 991.98) {
      this.switchText = true
    } else {
      this.switchText = false
    }
    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth < 991.98 || screen.orientation.angle === 90) {
        this.switchText = true
      } else {
        this.switchText = false
      }
    })
  },
  updated() {

  },
  methods: {
    goToPath(name) {
      this.$router.push({name: name}).catch(() => {
      })
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>

<style scoped>
.container_sidebar.container-sidebar {
  width: 10vw;
}

.img-logo {
  width: 80%;
}

.background_active.container-single-link {
  background: var(--color-f3f3f3);
}

.container-links {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  overflow: auto;
}

.container-links::-webkit-scrollbar {
  width: 0px;
}

.container-single-link {
  background: var(--color-f4f4f4);
  margin: 10px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px 15px 40px;
}

.text-link {
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-small);
  /*line-height: 18px;*/
  color: var(--color-black);
  margin-left: 10px;
}

.text-link-active {
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--x-small);
  /*line-height: 18px;*/
  color: var(--color-primary);
}

.container-logo {
  margin: 20px 0px 0px 0px;
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.container-sidebar {
  width: 15vw;
  display: flex;
  position: fixed;
  left: 0;
  flex-direction: column;
  z-index: 2;
  background: var(--color-f4f4f4);
  height: 100vh;
  border-right: 10px solid var(--color-f3f3f3);
}


/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container_single_link, .container-single-link {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .text-link {
    display: none;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .container-single-link {
    padding: 10px 15px 10px 15px;
  }
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}


</style>