import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";

export const useReportStore = defineStore({
    id: 'report',

    state: () => ({
        reports: null,
        report: null,
        arrayReportsInfinity: [],
        spinnerReport: false,
        optionsReport: null,
    }),
    actions: {
        getReports() {
            return new Promise((resolve, reject) => {
                axios.defaults.headers['Authorization'] = 'Bearer ' + useAuthStore().bearerToken

                axios.get('/api/associations/')
                    .then(res => {
                        this.reports = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },

    getters: {
        reportsGetters: state => {
            return state.reports
        },
        reportGetters: state => {
            return state.report
        },
        optionsReportGetters: state => {
            return state.optionsReport
        },
        arrayReportsInfinityGetters: state => {
            return state.arrayReportsInfinity
        },
        spinnerReportGetters: state => {
            return state.spinnerReport
        },

    }
})


