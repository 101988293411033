import axios from 'axios'
import { defineStore } from 'pinia'
//import {useUserStore} from "@/stores/users";


export const useAuthStore = defineStore({
    id: 'auth',


    state: () => ({
        user: null,
        bearerToken: null,
        refreshToken: null,
        errorToken: '',
        error: ''
    }),
    actions: {
        tokenGeneration(payload) {
            return new Promise((resolve, reject) => {
                axios.post("api/token", payload)
                    .then(res => {
                        axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.access
                        this.bearerToken = res.data.access
                        this.refreshToken = res.data.refresh
                        this.user = res.data.user
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response) {
                            this.error = err.response.data
                        }
                        reject(err)
                    })
            })
        },
        getRefreshToken(payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/token/refresh',payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters:{
        isAuthenticated: state => {
            return !!state.user
        },
        userGetters: state => {
            return state.user
        },
        errorGetters: state => {
            return state.error
        },
        errorTokenGetters: state => {
            return state.errorToken
        }
    },
    persist: true
})


