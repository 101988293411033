import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import router from "@/router";

export const useUserStore = defineStore("user", {
    state: () => ({
        users: null,
        arrayUsersInfinity: [],
        optionsUser: null,
        optionsUserDetail: null,
        user: null,
        allUsers: null,
        oldUser: null,
        spinnerUser: false
    }),
    actions: {
        getOptionsUser() {
            return new Promise((resolve, reject) => {

                axios.options('api/users/')
                    .then(res => {
                        this.optionsUser = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getUser(id) {
            return new Promise((resolve, reject) => {
                axios.get('api/users/' + id + '/')
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        editUser(payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/users/' + useAuthStore().userGetters.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteUser(id) {
            return new Promise((resolve, reject) => {
                axios.delete('/api/users/' + id + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        changePassword(payload) {
            return new Promise((resolve, reject) => {

                axios.patch('api/users/change-password/', {
                    old_password: payload.old_password,
                    password: payload.password,
                    confirmed_password: payload.confirmed_password,
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        setPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/users/' + payload.id + '/set-password/', {
                    password: payload.values.password,
                    confirmed_password: payload.values.confirmed_password
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        requestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/users/request-reset-password/', {
                        email: payload

                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        checkRequestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/users/check-request-reset-password/' + payload.uidb64 + '/' + payload.token)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        router.push('/login').catch(() => {
                            })
                        reject(err)
                    })
            })
        },
        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/users/reset-password/', {
                    password: payload.password,
                    token: payload.token,
                    uidb64: payload.uidb64
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },
    getters: {
        usersGetters: state => {
            return state.users
        },
        allUsersGetters: state => {
            return state.allUsers
        },
        oldUserGetters: state => {
            return state.oldUser
        },
        userGetters: state => {
            return state.user
        },
        arrayUsersInfinityGetters: state => {
            return state.arrayUsersInfinity
        },
        spinnerUserGetters: state => {
            return state.spinnerUser
        },
        optionsUserGetters: state => {
            return state.optionsUser
        },
        optionsUserDetailGetters: state => {
            return state.optionsUserDetail
        }
    }
})