<template>
  <div id="container_header" class="container-header">
    <div class="container-header-top">
      <span class="text-header">{{ getName() }}</span>
      <div class="container-content-header">

      </div>


<!--      QUESTO E' IL BOTTONE LATERALE PER PROFILE E LOGOUT-->

      <div @click="openDropdownMenu" v-if="storeAuth.userGetters"
           :class="{background_color_green: $route.name === 'profile'}" class="container-name">
        <img v-if="$route.name !== 'profile'" src="../../assets/shared/user.svg" alt="">
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
              stroke="#A3D7F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>


        <span :class="{'text-name-active': $route.name === 'profile'}" class="text-name">{{
            returnTypeAndEmail()
          }}</span>
      </div>
<!--      QUESTO E' IL DROPDOWN CHE SI APRE-->
      <div v-show="showDropdownMenu" class="container-dropdown-menu-header-bar">
        <div v-if="storeUser.userGetters && !storeUser.userGetters.is_superuser" @click="goToPath('profile')"
             class="container-single-dropdown-menu-header-bar">
          <img src="../../assets/shared/user_active.svg" alt="">
          <span class="title-x-small">Profilo</span>
        </div>
        <div @click="logout" class="container-single-dropdown-menu-header-bar">
          <img src="../../assets/shared/logout.svg" alt="">

          <span class="title-x-small">Logout</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/users";
import {useAuthStore} from "@/stores/auth";
import router from "@/router";
import {useDownloadStore} from "@/stores/download";
import {useReportStore} from "@/stores/reports";
import {usePraticheStore} from "@/stores/pratiche";
import {useContattiStore} from "@/stores/contatti";
import {useConciliazioniStore} from "@/stores/conciliazioni";

export default {
  name: "ComponentHeaderBar",
  setup() {
    const storeUser = useUserStore()
    const storeAuth = useAuthStore()
    return {
      storeUser,
      storeAuth
    }
  },
  data() {
    return {
      showDropdownMenu: false,
      changeText: false
    }
  },
  created() {
    this.emitter.on('closeDropdownMenu', () => {
      this.showDropdownMenu = false
    })
  },
  mounted() {
    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth < 991.98) {
        this.changeText = true
      } else {
        this.changeText = false
      }
      this.calculateStylesDropdownMenu()
    })
    this.calculateStylesDropdownMenu()
  },
  methods: {
    returnTypeAndEmail() {
      let type
      if (useAuthStore().userGetters.user_type === 'd') {
        type = 'Sportello'
      } else if (useAuthStore().userGetters.user_type === 'ad') {
        type = 'Admin'
      } else if (useAuthStore().userGetters.user_type === 'sad') {
        type = 'SuperAdmin'
      }
      return type + ': ' + useAuthStore().userGetters.username
    },
    goToPath(path) {
      this.showDropdownMenu = false
      if (path === 'profile') {
        this.$router.push('/profile').catch(() => {
        })
      }
    },
    logout() {
      useAuthStore().$reset()
      useConciliazioniStore().$reset()
      useContattiStore().$reset()
      useDownloadStore().$reset()
      usePraticheStore().$reset()
      useReportStore().$reset()
      useUserStore().$reset()
      router.push({name: 'login'}).catch(() => {
      })
    },
    calculateStylesDropdownMenu() {
      let container_name = document.getElementsByClassName('container-name')[0]
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      if (container_dropdown_menu_header_bar) {
        container_dropdown_menu_header_bar.style.width = container_name.getBoundingClientRect().width + 'px'
      }

    },
    openDropdownMenu() {
      // funzione per calcolare la posizione esatta per aprire il menu rispetto al nome dell'utente
      let text_name = document.getElementsByClassName('text-name')[0]
      let container_name = document.getElementsByClassName('container-name')[0]
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      if (text_name && container_name && container_dropdown_menu_header_bar) {
        container_dropdown_menu_header_bar.style.top = text_name.getBoundingClientRect().top + text_name.getBoundingClientRect().height + 15 + 'px'
        container_dropdown_menu_header_bar.style.width = container_name.getBoundingClientRect().width + 'px'
      }
      this.showDropdownMenu = !this.showDropdownMenu
      this.emitter.emit('checkStateDropdownMenu', this.showDropdownMenu)
    },
    getName() {
      let name
      if (this.$route.name === 'home') {
        name = 'Home'
      } else if (this.$route.name === 'pratiche'){
        name = 'Lista pratiche ' + '('+ usePraticheStore().totPraticheGetters+')'
      } else if (this.$route.name === 'contatti'){
        name = 'Lista contatti ' + '('+ useContattiStore().totContattiGetters+')'
      } else if (this.$route.name === 'conciliazioni'){
        name = 'Lista conciliazioni ' + '('+ useConciliazioniStore().totConciliazioniGetters+')'
      } else if (this.$route.name === 'reports') {
        name = 'Report'
      } else if (this.$route.name === 'profile') {
        name = 'Profilo'
      }

      return name
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
    })
    this.emitter.off('closeDropdownMenu', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    })
    this.emitter.off('closeDropdownMenu', () => {
    })
  }
}
</script>

<style scoped>
.btn-primary-custom img {
  margin-right: 10px;
}

.text-name-active.text-name {
  color: var(--color-f4f4f4);
}

.text-name {
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--small);
  /*line-height: 20px;*/
  color: var(--color-black);
  margin-left: 10px;
}

.background_color_green.container-name {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.container-name {
  background: #F3F3F3;
  display: flex;
  padding: 10px;
  margin-left: auto;
  align-items: center;
  cursor: pointer;
}

.container-single-dropdown-menu-header-bar img {
  margin-right: 10px;
}

.container-single-dropdown-menu-header-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
}

.text-x-small {
  margin-left: 10px;
}

.container-header-top {
  display: flex;
  align-items: center;
  width: 100%;
}

.container-content-header {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex-wrap: wrap;
}

.container-dropdown-menu-header-bar {
  position: absolute;
  right: 0;
  z-index: 1;
  background: #f3f3f3;
  height: auto;
  min-width: 150px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 25px;
  flex-direction: column;
  cursor: pointer;
}

.text-header {
  font-style: normal;
  margin-right: 25px;
  font-weight: var(--font-weight-bold);
  font-size: var(--small);
  /*line-height: 18px;*/
  color: var(--color-primary);
}

.container-header {
  padding: 25px;
  background: var(--color-f4f4f4);
  display: flex;
  flex-direction: column;
  z-index: 30;
  align-items: center;
  position: sticky;
  top: 0;
}
</style>